@import 'react-toastify/dist/ReactToastify.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Univers LT Pro';
    src: url('../assets/fonts/UniversLTPro-BoldExt.otf');
    font-weight: 700;
}

@font-face {
    font-family: 'Univers LT Pro';
    src: url('../assets/fonts/UniversLTPro-BlackExt.otf');
    font-weight: 800;
}

html {
    scroll-behavior: smooth;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar-track,
.custom-scrollbar-gray::-webkit-scrollbar-track {
    border-radius: 0.7rem;
    margin: 0.7rem 0;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 0.5rem;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #323232;
}

.custom-scrollbar-gray::-webkit-scrollbar {
    height: 0.25rem;
}

.custom-scrollbar-gray::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #777777;
}

.ProseMirror:focus {
    outline: none;
}

.ProseMirror p.is-editor-empty:first-child::before {
    color: #8f908c;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.bg-overlay-pink::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ff66d6;
    mix-blend-mode: multiply;
}

@keyframes clock-animation {
    0% {
        stroke-dashoffset: 471;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

.arclogo-bgyellow {
    filter: invert(0%) sepia(0%) saturate(1200%) hue-rotate(176deg)
        brightness(101%) contrast(90%);
}

.arclogo-bggreen {
    filter: invert(96%) sepia(4%) saturate(2041%) hue-rotate(58deg)
        brightness(99%) contrast(92%);
}

.arclogo-bgred {
    filter: invert(85%) sepia(55%) saturate(4347%) hue-rotate(332deg)
        brightness(104%) contrast(100%);
}

.arclogo-bgpurple {
    filter: invert(67%) sepia(67%) saturate(539%) hue-rotate(67deg)
        brightness(99%) contrast(81%);
}

.marquee-container {
    overflow-x: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: var(--width);
    transform: var(--transform);
}

.marquee-container &:hover div {
    animation-play-state: var(--pause-on-hover);
}

.marquee-container &:active div {
    animation-play-state: var(--pause-on-click);
}

.marquee {
    flex: 0 0 auto;
    min-width: var(--min-width);
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
    animation-play-state: var(--play);
    animation-delay: var(--delay);
    animation-direction: var(--direction);
}

@keyframes scroll {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.marquee .initial-child-container {
    flex: 0 0 auto;
    display: flex;
    min-width: auto;
    flex-direction: row;
}

.marquee .initial-child-container .child {
    transform: var(--transform);
}
